<template>
  <div class="w-p-100 h-p-100 ">
    <div class=" scroll-y w-p-100 h-p-100">
      <van-cell title="姓名" :value="detail.app_user_name" />
      <van-cell title="联系方式" :value="detail.app_user_mobile" />
      <van-cell title="所属组织" :value="detail.app_user_organization" />
      <van-cell title="邮箱" :value="detail.app_user_email" />
      <van-cell title="所属部门" :value="detail.app_user_department" />
      <div class="size-18 w-p-100 flex alignCenter justifyCenter">
        <div
          class="h-35 w-150 bg-0F60A7 flex alignCenter justifyCenter  w-p-100 color-fff padding-5 tM-30"
          @click="loginOut()"
        >
          退出登录
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { useRouter } from "vue-router";
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  setup() {
    const detail = reactive({
      app_user_name: localStorage.getItem("app_user_name") || "",
      app_user_mobile: localStorage.getItem("app_user_mobile") || "",
      app_user_organization:
        localStorage.getItem("app_user_organization") || "",
      app_user_email: localStorage.getItem("app_user_email") || "",
      app_user_department: localStorage.getItem("app_user_department") || ""
    });

    const router = useRouter();
    // 退出登录
    const loginOut = () => {
      Dialog.confirm({
        message: "此操作将退出登录，是否继续?"
      })
        .then(() => {
          localStorage.removeItem("app_user_mobile");
          localStorage.removeItem("app_organization_id");
          localStorage.removeItem("app_version");
          localStorage.removeItem("app_user_name");
          localStorage.removeItem("app_user_email");
          localStorage.removeItem("app_user_id");
          localStorage.removeItem("app_user_organization");
          localStorage.removeItem("app_user_department");
          localStorage.removeItem("app_token");
          router.push("/Login");
        })
        .catch(() => {});
    };
    // const getDetail = () =>{
    //     http.get({
    //         method:'query',
    //         queryId:1074
    //     })
    //     .then(res=>{
    //         console.log(res);
    //     })
    // }
    onMounted(() => {
      // getDetail();
    });
    return {
      detail,
      loginOut
    };
  }
};
</script>
